@use "sass:meta";
@use "sass:string";
@use "abstract/exportvariables_mixing" as variables;

@function str-replace($string, $search, $replace: '') {
    $index: str-index($string, $search);

    @if $index {
        @return str-slice($string, 1, $index - 1)+$replace +str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
    }

    @return $string;
}

@mixin add-css-variables($module) {
    :root {
        @each $name, $value in $module {
            // Replace "foundations" with "fs" in the variable name
            $replaced_name: str-replace($name, 'foundations', 'ff'); //str-replace($name, "foundations", "fs");

            // Output CSS variable
            --#{$replaced_name}: #{$value};
        }
    }
}

$variables: meta.module-variables('variables');

// Usages
@include add-css-variables($variables);